export const cardTypeOptions = [
  {
    value: 'cccd_chip',
    label: 'Căn Cước Công Dân',
  },
  {
    value: 'cmnd',
    label: 'Chứng Minh Nhân Dân',
  },
  {
    value: 'passport',
    label: 'Passport',
  },
  {
    value: '',
    label: '–',
  },
];

export const businessCardTypeOptions = [
  {
    value: 'business_certificate',
    label: 'Giấy chứng nhận đăng ký doanh nghiệp',
  },
];

export const cardTypeLabel = {
  cccd_chip: 'Căn Cước Công Dân',
  cmnd: 'Chứng Minh Nhân Dân',
  passport: 'Passport',
  business_certificate: 'Giấy chứng nhận đăng ký doanh nghiệp',
};

export const genderLabel = {
  male: 'Nam',
  female: 'Nữ',
};

export const genderOptions = [
  { value: 'male', label: 'Nam' },
  { value: 'female', label: 'Nữ' },
];

export const nationalityLabel = {
  vietnam: 'Việt Nam 🇻🇳',
  other: 'Nước ngoài',
};

export const nationalityOptions = [
  { value: 'vietnam', label: 'Việt Nam 🇻🇳' },
  { value: 'other', label: 'Nước ngoài' },
];

export const identificationStatusColors = {
  uploaded: 'cyan',
  confirmed: 'orange',
  approved: 'green',
  rejected: 'red',
};

export const securityAccountCardTypeOptions = [
  {
    value: 1,
    label: 'Căn Cước Công Dân',
  },
  {
    value: 2,
    label: 'Passport',
  },
];

export const securityAccountStatusColors = {
  1001: 'grey',
  1002: 'yellow',
  1003: 'green',
  1004: 'orange',
  1006: 'cyan',
  1007: 'purple',
  1008: 'red',
};

export const securityMutualAccountStatusColors = {
  1001: 'yellow',
  1002: 'green',
};

export const securityMutualAccountStatusApprove = 1002;